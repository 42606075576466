export default {
    methods: {
        processErrorResponse(response, observer, defaultMessage = null) {
            if (422 === response.status) {
                if (observer) {
                    observer.setErrors(response.data.errors);
                }

                return;
            }

            this.$toast.error(500 === response.status ? defaultMessage || this.$t('general.unknown_error') : response.data.message);

            if (observer) {
                observer.reset();
            }
        },
    },
};
